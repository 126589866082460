import {
  ACCEPT_INVITATION,
  CREATE_API_KEY,
  CREATE_ORGANIZATION,
  CREATE_PROJECT_CUSTOM_DOMAIN,
  CREATE_PROJECT_USER_EMAIL,
  CREATE_PROJECT_USERS,
  CREATE_PROJECTS,
  CREATE_SAML_PROVIDER,
  DECLINE_INVITATION,
  DELETE_API_KEY,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_USERS,
  DELETE_PROJECT,
  DELETE_PROJECT_CUSTOM_DOMAIN,
  DELETE_PROJECT_USER,
  DELETE_PROJECT_USER_EMAIL,
  DELETE_SAML_PROVIDER,
  generateApiUrl, GET_BILLING_USAGE,
  GET_CHECKOUT_SESSION,
  GET_CURRENT_USER,
  GET_CUSTOMER_PORTAL_SESSION,
  GET_INVITATION,
  GET_ORGANIZATION,
  GET_ORGANIZATION_USERS,
  GET_PASSKEY_PROJECT_DAILY_ACTIVE_USER,
  GET_PASSKEY_PROJECT_MONTHLY_ACTIVE_USER_LIST,
  GET_PROJECT,
  GET_PROJECT_AUDIT_LOGS,
  GET_PROJECT_CUSTOM_DOMAIN_STATUS,
  GET_PROJECT_DAILY_ACTIVE_USER,
  GET_PROJECT_DAILY_SIGNUP_STATS,
  GET_PROJECT_DAILY_TOTAL_USERS,
  GET_PROJECT_LAST_TOTAL_USERS,
  GET_PROJECT_MONTHLY_ACTIVE_USER,
  GET_PROJECT_MONTHLY_ACTIVE_USER_LIST,
  GET_PROJECT_MONTHLY_SIGNUP_STATS,
  GET_PROJECT_MONTHLY_TOTAL_USERS,
  GET_PROJECT_STATUS,
  GET_PROJECT_USER,
  GET_PROJECT_USERS,
  GET_PROJECTS,
  GET_SUBSCRIPTION,
  GET_USER_IMPORT_LOGS,
  INVITE_ORGANIZATION_USERS,
  LIST_INVITATIONS,
  LIST_USER_IMPORT_JOBS,
  REACTIVATE_PROJECT,
  REACTIVATE_PROJECT_CUSTOM_DOMAIN_STATUS,
  REVOKE_ORGANIZATION_USERS_INVITATION,
  SET_PROJECT_USER_EMAIL_AS_PRIMARY,
  UPDATE_ORGANIZATION,
  UPDATE_PROJECT,
  UPDATE_SAML_PROVIDER,
  UPLOAD_USER_IMPORT_FILE,
  WEBHOOKS,
} from "../const/Url";
import { Organization, UpdateOrganizationData } from "../models/Organization";
import {
  CreateApiKeyResponse,
  CreateUpdateSamlIdentityProvider,
  CustomDomain,
  Project,
  SamlIdentityProvider,
  UpdateProjectData
} from "../models/Project";
import { User } from "../models/User";
import { MockApiClient } from "./MockApiClient";
import { CreateEmailAddress, Email, ProjectUser, ProjectUsersWithTotalCount } from "../models/ProjectUser";
import { AuditLog, AuditLogsWithTotalCount } from '../models/AuditLog'
import { CheckoutSession, CustomerPortalSession, Subscription } from "../models/Subscription";
import { MonthlyActiveUser } from "../models/MonthlyActiveUser";
import { DailyActiveUser, MonthlyActiveUserStats } from "../models/DailyActiveUser";
import { ProjectStatus } from "../models/ProjectStatus";
import { InviteUserData, OrganizationUser } from "../models/OrganizationUser";
import { PrivateInvitation, PublicInvitation } from "../models/Invitation";
import { TotalUser } from "../models/TotalUser";
import { DailySignupStats, MonthlySignupStats } from "../models/SignUpStats";
import { UserImportJob } from "../models/UserImport";
import { CreateWebhook, DeleteWebhook, UpdateWebhook, Webhook } from "../models/Webhooks";
import { ProjectType } from "../const/types";
import { CreateCustomDomain } from "../models/CustomDomain";
import { BillingUsage } from "../models/BillingUsage";

export interface IApiClient {
  getCurrentUser(request: Request): Promise<User>

  getOrganization(request: Request, organizationId: string): Promise<Organization>

  getProjects(request: Request, organizationId: string): Promise<Project[]>

  getProject(request: Request, organizationId: string, projectId: string): Promise<Project>

  createOrganization(organizationName: string): Promise<Organization>

  updateOrganization(organizationId: string, data: UpdateOrganizationData): Promise<Organization>

  deleteOrganization(organizationName: string): Promise<any>

  getOrganizationUsers(organizationId: string): Promise<OrganizationUser[]>

  deleteUserFromOrganization(organizationId: string, userId: string): Promise<any>

  listInvitations(organizationId: string): Promise<PrivateInvitation[]>

  revokeInvitation(organizationId: string, invitationId: string): Promise<any>

  inviteUser(organizationId: string, data: InviteUserData): Promise<InviteUserData>

  createProject(organizationId: string, projectName: string, projectUrl: string, passwordsEnabled: boolean): Promise<Project>

  updateProject(organizationId: string, projectId: string, data: UpdateProjectData): Promise<Project>

  deleteProject(organizationId: string, projectId: string): Promise<any>

  getProjectStatus(organizationId: string, projectId: string): Promise<ProjectStatus>

  reactivateProject(organizationId: string, projectId: string): Promise<Project>

  getStripeCheckoutSession(organizationId: string, queryParams: URLSearchParams): Promise<CheckoutSession>

  getProjectUsers(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<ProjectUsersWithTotalCount>

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount>

  getProjectUser(request: Request, organizationId: string, projectId: string, userId: string): Promise<ProjectUser>

  deleteProjectUser(organizationId: string, projectId: string, userId: string): Promise<any>

  createProjectUser(organizationId: string, projectId: string, email: string, isEmailVerified: boolean): Promise<ProjectUser>

  createProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, data: CreateEmailAddress): Promise<Email>

  deleteProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any>

  setProjectUserEmailAsPrimary(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any>

  getMonthlyActiveUsers(request: Request, organizationId: string, projectId: string): Promise<MonthlyActiveUser>

  getDailyActiveUsers(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailyActiveUser[]>

  getMonthlyActiveUsersList(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlyActiveUserStats[]>

  getTotalUserDaily(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]>

  getTotalUserMonthly(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]>

  getTotalUserLast(organizationId: string, projectId: string): Promise<TotalUser>

  getSubscription(request: Request, organizationId: string): Promise<Subscription | null>

  getBillingUsage(request: Request, organizationId: string): Promise<BillingUsage>

  getStripeCustomerPortalSession(organizationId: string, queryParams: URLSearchParams): Promise<CustomerPortalSession>

  getInvitation(token: string): Promise<PublicInvitation>

  acceptInvitation(token: string): Promise<PublicInvitation>

  declineInvitation(token: string): Promise<any>

  getDailySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailySignupStats[]>

  getMonthlySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlySignupStats[]>

  uploadUserImportFile(organizationId: string, projectId: string, formData: FormData): Promise<any>

  listUserImportJobs(organizationId: string, projectId: string): Promise<UserImportJob[]>

  getUserImportJobLogs(organizationId: string, projectId: string, jobId: string): Promise<string>

  createApiKey(organizationId: string, projectId: string): Promise<CreateApiKeyResponse>

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any>

  getWebhooks(request: Request, organizationId: string, projectId: string): Promise<Webhook[]>

  createWebhook(organizationId: string, projectId: string, data: CreateWebhook): Promise<Webhook[]>

  updateWebhook(organizationId: string, projectId: string, data: UpdateWebhook): Promise<Webhook[]>

  deleteWebhook(organizationId: string, projectId: string, data: DeleteWebhook): Promise<Webhook[]>

  createSamlIdentityProvider(organizationId: string, projectId: string, data: CreateUpdateSamlIdentityProvider): Promise<SamlIdentityProvider>

  updateSamlIdentityProvider(organizationId: string, projectId: string, providerId: string, data: CreateUpdateSamlIdentityProvider): Promise<CreateUpdateSamlIdentityProvider>

  deleteSamlIdentityProvider(organizationId: string, projectId: string, providerId: string): Promise<any>

  createCustomDomain(organizationId: string, projectId: string, data: CreateCustomDomain): Promise<CreateCustomDomain>

  deleteCustomDomain(organizationId: string, projectId: string): Promise<any>

  reactivateCustomDomainVerification(organizationId: string, projectId: string): Promise<any>

  getCustomDomainStatus(organizationId: string, projectId: string): Promise<CustomDomain>
}

class ApiClientClass implements IApiClient {

  getCurrentUser(request: Request): Promise<User> {
    return fetch(generateApiUrl(GET_CURRENT_USER, {}), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getOrganization(request: Request, organizationId: string): Promise<Organization> {
    return fetch(generateApiUrl(GET_ORGANIZATION, { organizationId: organizationId }), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  getProjects(request: Request, organizationId: string): Promise<Project[]> {
    return fetch(generateApiUrl(GET_PROJECTS, { organizationId: organizationId }), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  getProject(request: Request, organizationId: string, projectId: string): Promise<Project> {
    return fetch(generateApiUrl(GET_PROJECT, { organizationId: organizationId, projectId: projectId }), {
      signal: request.signal,
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  createOrganization(organizationName: string): Promise<Organization> {
    return fetch(generateApiUrl(CREATE_ORGANIZATION, {}), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: organizationName
      })
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  updateOrganization(organizationId: string, data: UpdateOrganizationData): Promise<Organization> {
    return fetch(generateApiUrl(UPDATE_ORGANIZATION, { organizationId: organizationId }), {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteOrganization(organizationId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_ORGANIZATION, { organizationId: organizationId }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getOrganizationUsers(organizationId: string): Promise<OrganizationUser[]> {
    return fetch(generateApiUrl(GET_ORGANIZATION_USERS, { organizationId: organizationId }), {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteUserFromOrganization(organizationId: string, userId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_ORGANIZATION_USERS, { organizationId: organizationId, userId: userId }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  inviteUser(organizationId: string, data: InviteUserData): Promise<InviteUserData> {
    return fetch(generateApiUrl(INVITE_ORGANIZATION_USERS, { organizationId: organizationId }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  listInvitations(organizationId: string): Promise<PrivateInvitation[]> {
    return fetch(generateApiUrl(LIST_INVITATIONS, { organizationId: organizationId }), {
      method: "GET",
      credentials: "include"
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  revokeInvitation(organizationId: string, invitationId: string): Promise<any> {
    return fetch(generateApiUrl(REVOKE_ORGANIZATION_USERS_INVITATION, {
      organizationId: organizationId,
      invitationId: invitationId
    }), {
      method: "DELETE",
      credentials: "include"
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  createProject(organizationId: string, projectName: string, projectUrl: string, passwordsEnabled: boolean): Promise<Project> {
    return fetch(generateApiUrl(CREATE_PROJECTS, { organizationId: organizationId }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        application_name: projectName,
        application_url: projectUrl,
        passwords_enabled: passwordsEnabled
      })
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  updateProject(organizationId: string, projectId: string, data: UpdateProjectData): Promise<Project> {
    return fetch(generateApiUrl(UPDATE_PROJECT, { organizationId: organizationId, projectId: projectId }), {
      method: "PATCH",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteProject(organizationId: string, projectId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PROJECT, { organizationId: organizationId, projectId: projectId }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getProjectStatus(organizationId: string, projectId: string): Promise<ProjectStatus> {
    return fetch(generateApiUrl(GET_PROJECT_STATUS, {
        organizationId: organizationId,
        projectId: projectId
      }),
      {
        method: "GET",
        credentials: 'include'
      }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  reactivateProject(organizationId: string, projectId: string): Promise<Project> {
    return fetch(generateApiUrl(REACTIVATE_PROJECT, {
        organizationId: organizationId,
        projectId: projectId
      }),
      {
        method: "PUT",
        credentials: 'include'
      }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
  }

  getStripeCheckoutSession(organizationId: string, queryParams: URLSearchParams): Promise<CheckoutSession> {
    return fetch(generateApiUrl(GET_CHECKOUT_SESSION, {
      organizationId: organizationId,
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getProjectUsers(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<ProjectUsersWithTotalCount> {
    return fetch(generateApiUrl(GET_PROJECT_USERS, {
      organizationId: organizationId,
      projectId: projectId,
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json().then(logs => {
        return { user: logs as ProjectUser[], totalCount: parseInt(response.headers.get("X-Total-Count") || "-1", 10) }
      })
    })
  }

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount> {
    return fetch(generateApiUrl(GET_PROJECT_AUDIT_LOGS, {
      organizationId: organizationId,
      projectId: projectId,
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json().then(logs => {
        return { logs: logs as AuditLog[], totalCount: parseInt(response.headers.get("X-Total-Count") || "-1", 10) }
      })
    })
  }

  getProjectUser(request: Request, organizationId: string, projectId: string, userId: string): Promise<ProjectUser> {
    return fetch(generateApiUrl(GET_PROJECT_USER, {
      organizationId: organizationId,
      projectId: projectId,
      userId: userId
    }), {
      method: "GET",
      credentials: 'include',
      signal: request.signal
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteProjectUser(organizationId: string, projectId: string, userId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PROJECT_USER, {
      organizationId: organizationId,
      projectId: projectId,
      userId: userId
    }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  createProjectUser(organizationId: string, projectId: string, email: string, isEmailVerified: boolean): Promise<ProjectUser> {
    return fetch(generateApiUrl(CREATE_PROJECT_USERS, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: "POST",
      credentials: "include",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        is_verified: isEmailVerified
      })
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getSubscription(request: Request, organizationId: string): Promise<Subscription | null> {
    return fetch(generateApiUrl(GET_SUBSCRIPTION, { organizationId: organizationId }), {
      method: "GET",
      credentials: 'include',
      signal: request.signal
    }).then((response) => {
      if (response.ok) {
        return response.json()
      } else if (response.status === 404) {
        return Promise.resolve(null)
      } else {
        throw response
      }
    })
  }

  getBillingUsage(request: Request, organizationId: string): Promise<BillingUsage> {
    return fetch(generateApiUrl(GET_BILLING_USAGE, { organizationId: organizationId }), {
      method: "GET",
      credentials: 'include',
      signal: request.signal
    }).then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw response
      }
    })
  }

  getStripeCustomerPortalSession(organizationId: string, queryParams: URLSearchParams): Promise<CustomerPortalSession> {
    return fetch(generateApiUrl(GET_CUSTOMER_PORTAL_SESSION, {
      organizationId: organizationId,
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getMonthlyActiveUsers(request: Request, organizationId: string, projectId: string): Promise<MonthlyActiveUser> {
    return fetch(generateApiUrl(GET_PROJECT_MONTHLY_ACTIVE_USER, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "GET",
      credentials: 'include',
      signal: request.signal,
    }).then((response) => {
      if (response.status === 204) {
        return {
          id: "",
          count: 0,
          start_time: "",
          end_time: "",
          created_at: "",
          updated_at: ""
        }
      }
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getDailyActiveUsers(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailyActiveUser[]> {
    const url = projectType === "hanko_project" ? GET_PROJECT_DAILY_ACTIVE_USER : GET_PASSKEY_PROJECT_DAILY_ACTIVE_USER
    return fetch(generateApiUrl(url, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getMonthlyActiveUsersList(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlyActiveUserStats[]> {
    const url = projectType === "hanko_project" ? GET_PROJECT_MONTHLY_ACTIVE_USER_LIST : GET_PASSKEY_PROJECT_MONTHLY_ACTIVE_USER_LIST
    return fetch(generateApiUrl(url, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getInvitation(token: string): Promise<PublicInvitation> {
    return fetch(generateApiUrl(GET_INVITATION, { token: token }), {
      method: "GET",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  acceptInvitation(token: string): Promise<PublicInvitation> {
    return fetch(generateApiUrl(ACCEPT_INVITATION, { token: token }), {
      method: "POST",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  declineInvitation(token: string): Promise<any> {
    return fetch(generateApiUrl(DECLINE_INVITATION, { token: token }), {
      method: "DELETE",
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getTotalUserDaily(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]> {
    return fetch(generateApiUrl(GET_PROJECT_DAILY_TOTAL_USERS, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getDailySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailySignupStats[]> {
    return fetch(generateApiUrl(GET_PROJECT_DAILY_SIGNUP_STATS, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getTotalUserMonthly(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]> {
    return fetch(generateApiUrl(GET_PROJECT_MONTHLY_TOTAL_USERS, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getMonthlySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlySignupStats[]> {
    return fetch(generateApiUrl(GET_PROJECT_MONTHLY_SIGNUP_STATS, {
      organizationId: organizationId,
      projectId: projectId
    }) + `?${queryParams.toString()}`, {
      method: 'GET',
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  getTotalUserLast(organizationId: string, projectId: string): Promise<TotalUser> {
    return fetch(generateApiUrl(GET_PROJECT_LAST_TOTAL_USERS, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  createApiKey(organizationId: string, projectId: string): Promise<CreateApiKeyResponse> {
    return fetch(generateApiUrl(CREATE_API_KEY, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: "POST",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  uploadUserImportFile(organizationId: string, projectId: string, formData: FormData): Promise<any> {
    return fetch(generateApiUrl(UPLOAD_USER_IMPORT_FILE, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: "POST",
      credentials: 'include',
      body: formData
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  listUserImportJobs(organizationId: string, projectId: string): Promise<UserImportJob[]> {
    return fetch(generateApiUrl(LIST_USER_IMPORT_JOBS, {
      organizationId: organizationId,
      projectId: projectId
    }), {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_API_KEY, {
      organizationId: organizationId,
      projectId: projectId,
      apiKeyId: apiKeyId
    }), {
      method: "DELETE",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getUserImportJobLogs(organizationId: string, projectId: string, jobId: string): Promise<string> {
    return fetch(generateApiUrl(GET_USER_IMPORT_LOGS, {
      organizationId: organizationId,
      projectId: projectId,
      jobId: jobId
    }), {
      method: "GET",
      credentials: 'include',
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.text()
    })
  }

  getWebhooks(request: Request, organizationId: string, projectId: string): Promise<Webhook[]> {
    return fetch(generateApiUrl(WEBHOOKS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "GET",
      credentials: 'include',
      signal: request.signal,
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  createWebhook(organizationId: string, projectId: string, data: CreateWebhook): Promise<Webhook[]> {
    return fetch(generateApiUrl(WEBHOOKS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  updateWebhook(organizationId: string, projectId: string, data: UpdateWebhook): Promise<Webhook[]> {
    return fetch(generateApiUrl(WEBHOOKS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "PUT",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteWebhook(organizationId: string, projectId: string, data: DeleteWebhook): Promise<Webhook[]> {
    return fetch(generateApiUrl(WEBHOOKS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "DELETE",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  createSamlIdentityProvider(organizationId: string, projectId: string, data: CreateUpdateSamlIdentityProvider): Promise<SamlIdentityProvider> {
    return fetch(generateApiUrl(CREATE_SAML_PROVIDER, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  updateSamlIdentityProvider(organizationId: string, projectId: string, providerId: string, data: CreateUpdateSamlIdentityProvider): Promise<CreateUpdateSamlIdentityProvider> {
    return fetch(generateApiUrl(UPDATE_SAML_PROVIDER, {
      organizationId: organizationId,
      projectId: projectId,
      providerId: providerId,
    }), {
      method: "PUT",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteSamlIdentityProvider(organizationId: string, projectId: string, providerId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_SAML_PROVIDER, {
      organizationId: organizationId,
      projectId: projectId,
      providerId: providerId
    }), {
      method: "DELETE",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  createProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, data: CreateEmailAddress): Promise<Email> {
    return fetch(generateApiUrl(CREATE_PROJECT_USER_EMAIL, {
      organizationId: organizationId,
      projectId: projectId,
      userId: userId,
    }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PROJECT_USER_EMAIL, {
      organizationId: organizationId,
      projectId: projectId,
      userId: userId,
      emailId: emailId
    }), {
      method: "DELETE",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  setProjectUserEmailAsPrimary(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any> {
    return fetch(generateApiUrl(SET_PROJECT_USER_EMAIL_AS_PRIMARY, {
      organizationId: organizationId,
      projectId: projectId,
      userId: userId,
      emailId: emailId
    }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  createCustomDomain(organizationId: string, projectId: string, data: CreateCustomDomain): Promise<CreateCustomDomain> {
    return fetch(generateApiUrl(CREATE_PROJECT_CUSTOM_DOMAIN, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "POST",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  deleteCustomDomain(organizationId: string, projectId: string): Promise<any> {
    return fetch(generateApiUrl(DELETE_PROJECT_CUSTOM_DOMAIN, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "DELETE",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }

  getCustomDomainStatus(organizationId: string, projectId: string): Promise<CustomDomain> {
    return fetch(generateApiUrl(GET_PROJECT_CUSTOM_DOMAIN_STATUS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response.json()
    })
  }

  reactivateCustomDomainVerification(organizationId: string, projectId: string): Promise<any> {
    return fetch(generateApiUrl(REACTIVATE_PROJECT_CUSTOM_DOMAIN_STATUS, {
      organizationId: organizationId,
      projectId: projectId,
    }), {
      method: "PUT",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (!response.ok) {
        throw response
      }

      return response
    })
  }
}

export const ApiClient: IApiClient = process.env.REACT_APP_MOCK !== 'active' ? new ApiClientClass() : new MockApiClient()
import { Project, UpdateProjectData } from "../../../../models/Project";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../../components/form/OptionsWrapper";
import { SettingsHead } from "../../../../components/form/SettingsHead";
import { FormButtons } from "../../../../components/form/FormButtons";

interface Props {
  project: Project
  submit: (data: UpdateProjectData) => Promise<void>
}

interface State {
  isLoading: boolean
  enabled: boolean
  user_verification: "required" | "preferred" | "discouraged"
  attestation_preference: "direct" | "indirect" | "none"
  authenticator_attachment: "platform" | "cross-platform" | "no_preference"
}

export const SecurityKeys = (props: Props) => {
  const theme = useTheme()
  const { project, submit } = props
  const [state, setState] = useState<State>({
    isLoading: false,
    ...project.mfa.security_keys
  })

  const somethingChanged = () => {
    return project.mfa.security_keys.enabled !== state.enabled ||
      project.mfa.security_keys.user_verification !== state.user_verification ||
      project.mfa.security_keys.attestation_preference !== state.attestation_preference ||
      project.mfa.security_keys.authenticator_attachment !== state.authenticator_attachment
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSelectChange = (e: SelectChangeEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    await submit({
      mfa: {
        ...project.mfa,
        security_keys: {
          ...project.mfa.security_keys,
          enabled: state.enabled,
          attestation_preference: state.attestation_preference,
          authenticator_attachment: state.authenticator_attachment,
          user_verification: state.user_verification
        },
      }
    })
    setState({ ...state, isLoading: false })
  }

  const onCancel = () => {
    setState({
      ...state,
      ...project.mfa.security_keys
    })
  }

  return <Box>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper
        sx={{
          borderBottom: `1px solid ${theme.palette.grey["900"]}`
        }}>
        <SettingsHead
          description="Users can set up security keys (e.g. YubiKey, Solokey) as second factors. 2FA needs to be enabled for this setting to have an effect."
          title="Security keys" sx={{ mb: 1 }} minElementsVersion="1.1" />
        <Switch name="enabled" color="success" checked={state.enabled} onChange={onChange} />
      </SettingsSwitchOptionWrapper>

      <SettingsOptionWrapper>
        <SettingsHead title="User verification"
                      description="Select whether the user should be verified when using the security key (usually with a PIN)."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.1" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="user_verification">User verification</InputLabel>
          <Select labelId="user_verification" id="user_verification_select"
                  name="user_verification" label="User verification" onChange={onSelectChange}
                  value={state.user_verification}>
            <MenuItem key="required" value="required">Required</MenuItem>
            <MenuItem key="preferred" value="preferred">Preferred</MenuItem>
            <MenuItem key="discouraged" value="discouraged">Discouraged</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>

      <SettingsOptionWrapper>
        <SettingsHead title="Resolve security key brand and type"
                      description="Attempt to retrieve the attestation statement and the AAGUID of the security key to determine the brand and type (e.g. 'Yubico YubiKey 5C') and use it instead of a generic name ('Security Key'). 'direct' attempts to retrieve this information directly from the authenticator device, while 'indirect' values may be altered by the client, e.g. for privacy reasons."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.1" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="attestation_preference">Attestation preference</InputLabel>
          <Select labelId="attestation_preference" id="attestation_preference_select"
                  name="attestation_preference" label="Attestation preference" onChange={onSelectChange}
                  value={state.attestation_preference}>
            <MenuItem key="direct" value="direct">Direct</MenuItem>
            <MenuItem key="indirect" value="indirect">Indirect</MenuItem>
            <MenuItem key="none" value="none">None</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>

      <SettingsOptionWrapper>
        <SettingsHead title="Authenticator attachment"
                      description="Specify which type of security key should be used. This setting can affect the user experience when setting up a security key."
                      size="small"
                      sx={{ mb: 0 }} minElementsVersion="1.1" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small" disabled={!state.enabled}>
          <InputLabel id="authenticator_attachment">Authenticator attachment</InputLabel>
          <Select labelId="authenticator_attachment" id="authenticator_attachment_select"
                  name="authenticator_attachment" label="Authenticator attachment" onChange={onSelectChange}
                  value={state.authenticator_attachment}>
            <MenuItem key="platform" value="platform">Platform</MenuItem>
            <MenuItem key="cross-platform" value="cross-platform">Cross-platform</MenuItem>
            <MenuItem key="no_preference" value="no_preference">No preference</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>

      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}
import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../client/ApiClient";
import { Width } from "../../../const/Width";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  useTheme
} from "@mui/material";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { SettingsOptionWrapper, SettingsSwitchOptionWrapper } from "../../../components/form/OptionsWrapper";
import { FormButtons } from "../../../components/form/FormButtons";

interface State {
  isLoading: boolean
  sessionDuration: string
  serverSideSessionEnabled: boolean
  serverSideSessionLimit: number
  cookie_retention: "session" | "persistent" | "prompt"
}

export const Session = () => {
  const { project, organization } = useOutletContext() as ContextType
  const revalidator = useRevalidator()
  const theme = useTheme()
  const [state, setState] = useState<State>({
    isLoading: false,
    sessionDuration: project.session?.lifespan ?? "1h",
    serverSideSessionEnabled: project.session?.server_side?.enabled ?? false,
    serverSideSessionLimit: project.session?.server_side?.limit ?? 5,
    cookie_retention: project.session?.cookie_retention ?? "persistent",
  })
  const { error, success } = useContext(SnackbarContext)

  const somethingChanged = () => {
    return (project.session?.lifespan ?? "1h") !== state.sessionDuration ||
      (project.session?.server_side?.enabled ?? false) !== state.serverSideSessionEnabled ||
      (project.session?.server_side?.limit ?? 5) !== state.serverSideSessionLimit ||
      (project.session?.cookie_retention ?? "persistent") !== state.cookie_retention
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setState({ ...state, isLoading: true })
    try {
      await ApiClient.updateProject(organization.id, project.id, {
        session_lifespan: state.sessionDuration,
        server_side_session: {
          enabled: state.serverSideSessionEnabled,
          limit: state.serverSideSessionLimit,
        },
        session_cookie_retention: state.cookie_retention,
      })
      revalidator.revalidate()
      success("Settings updated")
    } catch (e) {
      error("failed to update settings")
    }
    setState({ ...state, isLoading: false })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "checkbox") {
      setState({
        ...state,
        [e.target.name]: e.target.checked,
      })
    } else if (e.target.type === "number") {
      setState({
        ...state,
        [e.target.name]: e.target.valueAsNumber,
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSelectChange = (e: SelectChangeEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onCancel = () => {
    setState({
      ...state,
      sessionDuration: project.session?.lifespan ?? "1h",
      serverSideSessionEnabled: project.session?.server_side?.enabled ?? false,
      serverSideSessionLimit: project.session?.server_side?.limit ?? 5,
    })
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: Width.NORMAL,
    gap: 5
  }}>
    <form onSubmit={onSubmit}>
      <SettingsSwitchOptionWrapper sx={{
        borderBottom: `1px solid ${theme.palette.grey["900"]}`
      }}>
        <SettingsHead description="Configure session-related settings." title="Session" sx={{ mb: 1 }} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Session duration"
                      description="Set how long JWTs will be valid."
                      size="small" sx={{ mb: 0 }} />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small">
          <InputLabel id="sessionDuration">Session duration</InputLabel>
          <Select labelId="sessionDuration" id="session_duration_select"
                  name="sessionDuration" label="Session duration" onChange={onSelectChange}
                  value={state.sessionDuration}>
            <MenuItem key="1m" value="1m">1 min</MenuItem>
            <MenuItem key="1h" value="1h">1 hour</MenuItem>
            <MenuItem key="12h" value="12h">12 hours</MenuItem>
            <MenuItem key="24h" value="24h">24 hours</MenuItem>
            <MenuItem key="168h" value="168h">7 days</MenuItem>
            <MenuItem key="336h" value="336h">14 days</MenuItem>
            <MenuItem key="720h" value="720h">1 month</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Stay signed in (cookie type)"
                      description="Hanko cookies can be set to be either persistent or session cookies."
                      helpText="Session cookies are typically deleted from the browser when the browser window is closed, whereas the user remains logged in for the session duration with persistent cookies. With 'prompt', Hanko Elements displays a 'Stay signed in' checkbox on the login form and lets the user decide."
                      size="small" sx={{ mb: 0 }} minElementsVersion="1.3" />
        <FormControl fullWidth sx={{ maxWidth: { xs: '100%', md: '220px' } }} size="small">
          <InputLabel id="cookie_retention">Cookie type</InputLabel>
          <Select labelId="cookie_retention" id="cookie_retention_select"
                  name="cookie_retention" label="Cookie type" onChange={onSelectChange}
                  value={state.cookie_retention}>
            <MenuItem key="session" value="session">Session</MenuItem>
            <MenuItem key="persistent" value="persistent">Persistent</MenuItem>
            <MenuItem key="prompt" value="prompt">Prompt</MenuItem>
          </Select>
        </FormControl>
      </SettingsOptionWrapper>
      <SettingsSwitchOptionWrapper>
        <SettingsHead
          description="Enable server-side session storage and remote session revocation. If enabled, Hanko Profile lists the user’s sessions (Elements 1.1 or newer)."
          title="Server-side sessions"
          sx={{ mb: 1 }} size="small" />
        <Switch name="serverSideSessionEnabled" color="success" checked={state.serverSideSessionEnabled}
                onChange={onChange} />
      </SettingsSwitchOptionWrapper>
      <SettingsOptionWrapper>
        <SettingsHead title="Session limit"
                      description="Controls how many active sessions a user can have at the same time. The oldest session becomes invalid if a new session is created above the limit."
                      size="small" sx={{ mb: 0 }} />
        <TextField fullWidth label="Session limit" sx={{ maxWidth: { xs: '100%', md: '220px' } }}
                   type="number"
                   inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 100 }}
                   name="serverSideSessionLimit" size="small" onChange={onChange}
                   value={state.serverSideSessionLimit} disabled={!state.serverSideSessionEnabled} />
      </SettingsOptionWrapper>
      <FormButtons onCancel={onCancel} loading={state.isLoading} disabled={!somethingChanged()} />
    </form>
  </Box>
}
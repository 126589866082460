import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  createSearchParams,
  defer,
  Navigate,
  Route,
  RouterProvider
} from "react-router-dom";
import ProjectsOverview from "./pages/Organizations/ProjectsOverview/ProjectsOverview";
import Billing from "./pages/Organizations/Billing/Billing";
import Settings from "./pages/Organizations/Settings/Settings";
import Dashboard from "./pages/Projects/Dashboard/Dashboard";
import User from "./pages/Projects/User/User";
import Events from "./pages/Projects/Events/Events";
import ProjectWrapper from "./pages/Projects/ProjectWrapper";
import OrganizationWrapper from "./pages/Organizations/OrganizationWrapper";
import NoOrganizationWrapper from "./pages/Organizations/NoOrganizationWrapper";
import { Login } from "./pages/Login";
import { NoOrganization } from "./pages/Organizations/NoOrganization";
import { emptyNavbarItems, organizationNavbarItems } from "./components/Navbar/navbarItems";
import { ErrorBoundary } from "./pages/ErrorBoundary";
import { ApiClient } from "./client/ApiClient";
import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import { OrganizationLoaderWrapper } from "./pages/Organizations/OrganizationLoaderWrapper";
import { Users as OrganizationUsers } from "./pages/Organizations/Users";
import { ErrorNotFound } from "./pages/404";
import { UserDetail } from "./pages/Projects/User/UserDetail/UserDetail";
import { UserWrapper } from "./pages/UserWrapper";
import { General } from "./pages/Projects/Settings/General";
import { Smtp } from "./pages/Projects/Settings/Smtp";
import { Authentication } from "./pages/Projects/Settings/Authentication";
import { Invitation } from "./pages/Invitation";
import { AuthProvider } from "./pages/Projects/Settings/AuthProvider";
import { UserImport } from "./pages/Projects/User/UserImport";
import { AdminApi } from "./pages/Projects/Settings/AdminApi";
import { Webhooks } from "./pages/Projects/Settings/Webhooks";
import PasskeyProjectWrapper from "./pages/PasskeyProject/PasskeyProjectWrapper"
import { PasskeyProjectApiClient } from "./client/PasskeyProjectApiClient";
import { Dashboard as PasskeyDashboard } from "./pages/PasskeyProject/Dashboard/Dashboard"
import { Events as PasskeyEvents } from "./pages/PasskeyProject/Events/Events"
import { General as PasskeyGeneral } from "./pages/PasskeyProject/Settings/General"
import { Webauthn as PasskeyWebauthn } from "./pages/PasskeyProject/Settings/Webauthn"
import { User as PasskeyUser } from "./pages/PasskeyProject/User/User"
import { UserDetail as PasskeyUserDetail } from "./pages/PasskeyProject/User/UserDetail"
import { ApiKeys as PasskeyApiKeys } from "./pages/PasskeyProject/Settings/ApiKeys"
import { UserAccount } from "./pages/Projects/Settings/UserAccount/UserAccount";
import { Session } from "./pages/Projects/Settings/Session";
import { EnterpriseSSO } from "./pages/Projects/Settings/EnterpriseSSO/EnterpriseSSO";
import { Urls } from "./pages/Projects/Settings/urls/Urls";
import { MFA } from "./pages/Projects/Settings/MFA/MFA";
import { CustomDomain } from "./pages/Projects/Settings/CustomDomain";

declare global {
  interface Window {
    REACT_APP_LOGIN_PROVIDER_URL: string
    REACT_APP_API_HOST: string
    REACT_APP_DISABLE_PROJECT_CREATION: boolean
  }
}

const navbarWidth = 300;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App/>} id="root" errorElement={<ErrorNotFound/>}>
      <Route index element={<Navigate to="/organizations" replace/>}/>
      <Route id="user" element={<UserWrapper/>} errorElement={<ErrorBoundary/>} loader={async ({request}) => {
        return await ApiClient.getCurrentUser(request)
      }}>
        <Route id="invitation" path="invitation" element={<Invitation/>}/>
        <Route element={<NoOrganizationWrapper navbarItems={emptyNavbarItems} navbarWidth={navbarWidth}/>}>
          <Route path="/organizations" element={<NoOrganization/>}/>
        </Route>

        <Route path="/organizations/:organizationId" element={<OrganizationLoaderWrapper/>}
               id="organization_loader_wrapper" loader={
          ({request, params}) => {
            return defer({
              organization: ApiClient.getOrganization(request, params.organizationId as string)
            })
          }}>
          <Route index element={<Navigate to="projects" replace/>}/>
          <Route element={<OrganizationWrapper navbarItems={organizationNavbarItems} navbarWidth={navbarWidth}/>}
                 id="organization_wrapper">
            <Route index element={<Navigate to="projects"/>}/>
            <Route path="projects" element={<ProjectsOverview/>} id="organization_projects"
                   loader={
                     async ({request, params}) => {
                       return defer({
                         subscription: await ApiClient.getSubscription(request, params.organizationId as string),
                         projects: ApiClient.getProjects(request, params.organizationId as string),
                         passkeyProjects: PasskeyProjectApiClient.getProjects(request, params.organizationId as string)
                       })
                     }}
            />
            <Route path="settings" element={<Settings/>} id="organization_settings" loader={
              ({request, params}) => {
                return defer({
                  subscription: ApiClient.getSubscription(request, params.organizationId as string),
                  projects: ApiClient.getProjects(request, params.organizationId as string)
                })
              }
            }/>
            <Route path="billing" element={<Billing/>} id="organization_billing" loader={
              ({request, params}) => {
                return defer({
                  subscription: ApiClient.getSubscription(request, params.organizationId as string),
                  usage: ApiClient.getBillingUsage(request, params.organizationId as string)
                })
              }}
            />
            <Route path="users" element={<OrganizationUsers/>} id="organization_users" loader={
              async ({request, params}) => {
                return defer({
                  subscription: await ApiClient.getSubscription(request, params.organizationId as string)
                })
              }}/>
            <Route path="*" element={<Navigate to="projects"/>}/>
          </Route>
          <Route path="projects/:projectId" element={<ProjectWrapper navbarWidth={navbarWidth}/>} id="project_wrapper"
                 loader={
                   ({request, params}) => {
                     return defer({
                       project: ApiClient.getProject(request, params.organizationId as string, params.projectId as string)
                     })
                   }
                 }>
            <Route index element={<Navigate to="dashboard" replace/>} id="project_index"/>
            <Route path="dashboard" element={<Dashboard/>} id="project_dashboard" loader={
              ({request, params}) => {
                const now = new Date()
                const startTime = new Date(now)
                const endTime = new Date(now)

                startTime.setUTCHours(0, 0, 0, 0)
                startTime.setUTCDate(now.getUTCDate() - 31)

                endTime.setUTCHours(23, 59, 59, 999)
                endTime.setUTCDate(now.getUTCDate() - 1)

                const userQueryParams = createSearchParams({"page": "1", "per_page": "1"})

                return defer({
                  mau: ApiClient.getMonthlyActiveUsers(request, params.organizationId as string, params.projectId as string),
                  users: ApiClient.getProjectUsers(params.organizationId as string, params.projectId as string, userQueryParams),
                })
              }
            }/>
            <Route path="users" id="project_users">
              <Route index element={<User/>} id="project_users_list"/>
              <Route path=":userId" element={<UserDetail/>} id="project_user_detail" loader={
                ({request, params}) => {
                  return defer({
                    user: ApiClient.getProjectUser(request, params.organizationId as string, params.projectId as string, params.userId as string)
                  })
                }
              }/>
              <Route path="import" element={<UserImport/>} id="project_user_import"/>
            </Route>
            <Route path="events" element={<Events/>} id="project_events"/>
            <Route path="settings" id="project_settings">
              <Route index element={<Navigate to="general" replace/>}/>
              <Route path="general" element={<General/>} id="project_settings_general"/>
              <Route path="smtp" element={<Smtp/>} id="project_settings_smtp" loader={
                async ({request, params}) => {
                  return defer({
                    subscription: await ApiClient.getSubscription(request, params.organizationId as string)
                  })
                }}/>
              <Route path="user_account" element={<UserAccount/>} id="project_settings_user_account"/>
              <Route path="urls" element={<Urls/>} id="project_settings_urls"/>
              <Route path="authentication" element={<Authentication/>} id="project_settings_authentication"/>
              <Route path="mfa" element={<MFA />} id="project_settings_mfa" />
              <Route path="auth_provider" element={<AuthProvider/>} id="project_auth_provider"/>
              <Route path="enterprise_sso" element={<EnterpriseSSO/>} id="project_enterprise_sso" loader={
                async ({request, params}) => {
                  return defer({
                    subscription: await ApiClient.getSubscription(request, params.organizationId as string)
                  })
                }} />
              <Route path="admin_api" element={<AdminApi/>} id="admin_api" loader={
                async ({request, params}) => {
                  return defer({
                    subscription: await ApiClient.getSubscription(request, params.organizationId as string)
                  })
                }}/>
              <Route path="webhooks" element={<Webhooks/>} id="project_settings_webhooks" loader={
                async ({request, params}) => {
                  return defer({
                    subscription: await ApiClient.getSubscription(request, params.organizationId as string),
                    webhooks: await ApiClient.getWebhooks(request, params.organizationId as string, params.projectId as string)
                  })
                }}/>
              <Route path="session" element={<Session/>} id="project_settings_session" />
              <Route path="custom_domain" element={<CustomDomain />} id="project_settings_custom_domain" />
              <Route path="*" element={<Navigate to="general" replace/>}/>
            </Route>
            <Route path="*" element={<Navigate to="dashboard" replace/>}/>
          </Route>
          <Route path="passkey_projects/:projectId" element={<PasskeyProjectWrapper navbarWidth={navbarWidth}/>}
                 id="passkey_project_wrapper"
                 loader={
                   ({request, params}) => {
                     return defer({
                       project: PasskeyProjectApiClient.getProject(request, params.organizationId as string, params.projectId as string)
                     })
                   }
                 }>
            <Route index element={<Navigate to="dashboard" replace/>} id="passkey_project_index"/>
            <Route path="dashboard" element={<PasskeyDashboard/>} id="passkey_project_dashboard"/>
            <Route path="users" id="passkey_project_users">
              <Route index element={<PasskeyUser/>} id="passkey_project_users_list"/>
              <Route path=":userId" element={<PasskeyUserDetail/>} id="passkey_project_user_detail" loader={
                ({request, params}) => {
                  return defer({
                    // TODO: passkey_project
                    // user: PasskeyProjectApiClient.getProjectUser(request, params.organizationId as string, params.projectId as string, params.userId as string)
                  })
                }
              }/>
            </Route>
            <Route path="events" element={<PasskeyEvents/>} id="passkey_project_events"/>
            <Route path="settings" id="passkey_project_settings">
              <Route index element={<Navigate to="general" replace/>}/>
              <Route path="general" element={<PasskeyGeneral/>} id="passkey_project_settings_general"/>
              <Route path="webauthn" element={<PasskeyWebauthn/>} id="passkey_project_settings_webauthn"/>
              <Route path="api_keys" element={<PasskeyApiKeys/>} id="passkey_api_keys" loader={
                ({request, params}) => {
                  return defer({
                    apiKeys: PasskeyProjectApiClient.getApiKeys(params.organizationId as string, params.projectId as string)
                  })
                }
              }/>
              <Route path="*" element={<Navigate to="general" replace/>}/>
            </Route>
            <Route path="*" element={<Navigate to="dashboard" replace/>}/>

          </Route>
          <Route path="*" element={<Navigate to="projects" replace/>}/>
        </Route>
      </Route>
      <Route path="login" element={<Login/>}/>
      <Route path="*" element={<Navigate to="/organizations" replace/>}/>
    </Route>
  )
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

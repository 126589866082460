import { Box, Card, CardContent, Typography } from "@mui/material";
import { normalise } from "../helper/ProgressBar";
import { CustomLinearProgress } from "./LinearProgress";

interface Props {
  title: string
  max: number
  current: number
}

export const UsageCard = (props: Props) => {
  const { title, max, current } = props

  return <Card elevation={0} sx={{
    borderRadius: '16px',
    width: '100%',
    boxShadow: '0px 4px 4px 0px #000000',
    background: '#262B3566',
    border: '1px solid #262B35'
  }}>
    <CardContent sx={{
      mx: 5,
      my: 4,
      gap: 3,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" fontSize="1.125rem" fontWeight="700">{title}</Typography>
        <Typography variant="body2">{current}/{max}</Typography>
      </Box>
      <CustomLinearProgress sx={{ borderRadius: 5, height: '8px' }} variant="determinate"
                            value={normalise(current, max)} />
    </CardContent>
  </Card>
}

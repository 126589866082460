import { Box, LinearProgress, linearProgressClasses, styled, Typography } from "@mui/material";
import { Subscription } from "../../../models/Subscription";
import { Organization } from "../../../models/Organization";
import { BillingUsage } from "../../../models/BillingUsage";
import { normalise } from "../../../helper/ProgressBar";
import { ContactSalesCard } from "./ContactSalesCard";
import { CustomLinearProgress } from "../../../components/LinearProgress";

const CustomBox = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  p: 3,
  border: '1px solid #262B35',
  boxShadow: '0px 4px 4px 0px #000000',
  background: '#262B3566',
  borderRadius: '16px',
}))

interface Props {
  organization: Organization
  subscription: Subscription
  usage: BillingUsage
}

export const Paid = (props: Props) => {
  const { organization, subscription, usage } = props

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: subscription.prices[0].currency ?? "USD"
  })
  const dateFormatter = new Intl.DateTimeFormat("default", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC"
  })

  const includedMaus = subscription.included_quantities.find((item) => item.type === "maus")?.quantity ?? 0

  const baseFee = (subscription.prices.find((item) => item.feature === "base_fee")?.amount ?? 0) / 100
  const mauFee = (subscription.prices.find((item) => item.feature === "mau")?.amount ?? 0) / 100
  const enterpriseConnectionsFee = (subscription.prices.find((item) => item.feature === "enterprise_connections")?.amount ?? 0) / 100

  const sum = baseFee + mauFee * usage.mau.paid + enterpriseConnectionsFee * usage.enterprise_connections.paid

  console.log(subscription.current_period_end)

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
    <CustomBox sx={{
      py: 2,
      justifyContent: 'space-between',
      flexDirection: { xs: 'column', md: 'row' },
      gap: { xs: 1, md: 0 }
    }}>
      <Typography variant="body2">Billing period</Typography>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <Typography variant="body2" color="#7C8E9C">Start:</Typography>
          <Typography variant="body2">{dateFormatter.format(subscription.current_period_start * 1000)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <Typography variant="body2" color="#7C8E9C">End:</Typography>
          <Typography variant="body2">{dateFormatter.format(subscription.current_period_end * 1000)}</Typography>
        </Box>
      </Box>
    </CustomBox>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <CustomBox sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Projects</Typography>
        <ProgressIndicator current={usage.projects} max={organization.limit.max_projects} />
      </CustomBox>
      <CustomBox sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Base fee</Typography>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <Typography variant="body2">{currencyFormatter.format(baseFee)} </Typography>
          <Typography variant="body2" color="#7C8E9C">/ month</Typography>
        </Box>
      </CustomBox>
      <CustomBox sx={{ flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="h6">MAU</Typography>
          <Typography
            variant="h6">{usage.mau.total}</Typography> {/* TODO: Maybe format number as we do on the Dashboard */}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">Free</Typography>
          <ProgressIndicator current={usage.mau.free} max={includedMaus} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">Paid ({currencyFormatter.format(mauFee)} / MAU)</Typography>
          <Typography variant="body2">{usage.mau.paid}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">Total</Typography>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <Typography variant="body2">{currencyFormatter.format(mauFee * usage.mau.paid)}</Typography>
            <Typography variant="body2" color="#7C8E9C">/ month</Typography>
          </Box>
        </Box>
      </CustomBox>
      <CustomBox sx={{ flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="h6">Enterprise connections</Typography>
          <Typography variant="h6">{usage.enterprise_connections.total}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">Paid ({currencyFormatter.format(enterpriseConnectionsFee)} per connection /
            month)</Typography>
          <Typography variant="body2">{usage.enterprise_connections.paid}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">Total</Typography>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <Typography
              variant="body2">{currencyFormatter.format(enterpriseConnectionsFee * usage.enterprise_connections.paid)}</Typography>
            <Typography variant="body2" color="#7C8E9C">/ month</Typography>
          </Box>
        </Box>
      </CustomBox>
    </Box>
    <CustomBox sx={{ py: 2, justifyContent: 'space-between' }}>
      <Typography variant="body2">Current month costs</Typography>
      <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
        <Typography variant="body2">{currencyFormatter.format(sum)}</Typography>
        <Typography variant="body2" color="#7C8E9C">/ month</Typography>
      </Box>
    </CustomBox>
    <ContactSalesCard sx={{mt: 2}} />
  </Box>
}

const ProgressIndicator = (props: { current: number, max: number }) => {
  const { current, max } = props

  return <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    <Typography variant="body2">{current} / {max}</Typography>
    <CustomLinearProgress sx={{ borderRadius: 5, height: '8px', width: '100px' }} variant="determinate"
                          value={normalise(current, max)} />
  </Box>
}

import { AuditLog } from "../../models/AuditLog";
import React, { useState } from "react";
import { Box, Collapse, IconButton, Link, TableCell, TableRow, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";


export const EventTableRow = (props: {
  auditLog: AuditLog,
  matchesLgDown: boolean,
  matchesMdDown: boolean,
  projectType: "hanko_project" | "passkey_project"
}) => {
  const [open, setOpen] = useState(false)
  const { auditLog, matchesLgDown, matchesMdDown, projectType } = props

  const occurredAt = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  }).format(new Date(auditLog.created_at))

  return <>
    <TableRow hover key={auditLog.id} onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }}>
      <TableCell sx={{ borderBottom: 'unset' }}>{auditLog.type}</TableCell>
      <TableCell sx={{
        display: 'table-cell',
        borderBottom: 'unset'
      }} hidden={matchesLgDown}>{auditLog.actor_user_id}</TableCell>
      <TableCell sx={{ display: matchesLgDown ? 'none' : 'table-cell', borderBottom: 'unset' }}
                 hidden={matchesLgDown}>{auditLog.meta_source_ip}</TableCell>
      <TableCell sx={{ display: matchesMdDown ? 'none' : 'table-cell', borderBottom: 'unset' }}
                 hidden={matchesMdDown}>{occurredAt}</TableCell>
      <TableCell sx={{ borderBottom: 'unset' }}>
        <IconButton aria-label="expand row"
                    size="small"
                    disableRipple
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{ pb: 0, pt: 0 }} colSpan={5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 2, display: 'flex', gap: 0, flexDirection: 'column' }}>
            {auditLog.error !== undefined && auditLog.error !== null && auditLog.error !== "" ?
              <EventDetail title="Error" content={auditLog.error} /> : null}
            <EventDetail title="Http RequestID" content={auditLog.meta_http_request_id} />
            {matchesLgDown ? <EventDetail title="IP" content={auditLog.meta_source_ip} /> : null}
            <EventDetail title="User Agent" content={auditLog.meta_user_agent} />
            {(auditLog.actor_user_id !== undefined) ?
              <EventDetail title="User ID" content={auditLog.actor_user_id} isLink /> : null}
            {matchesMdDown ? <EventDetail title="Occurred at (UTC)" content={occurredAt} /> : null}
            {auditLog.details?.hasOwnProperty("username") ?
              <EventDetail title="Username" content={auditLog.details["username"]} /> : null}
            {auditLog.details?.hasOwnProperty("email") ?
              <EventDetail title="Email" content={auditLog.details["email"]} /> : null}
            {auditLog.details?.hasOwnProperty("password") ?
              <EventDetail title="Password configured" content="" /> : null}
            {auditLog.details?.hasOwnProperty("passkey") ? <EventDetail title="Passkey configured" content="" /> : null}
            {auditLog.details?.hasOwnProperty("otp_secret") ? <EventDetail title="TOTP configured" content="" /> : null}
            {auditLog.details?.hasOwnProperty("security_key") ?
              <EventDetail title="Security key configured" content="" /> : null}
            {auditLog.details?.hasOwnProperty("login_method") ?
              <EventDetail title="Login method" content={auditLog.details["login_method"]} /> : null}
            {auditLog.details?.hasOwnProperty("mfa_method") ?
              <EventDetail title="MFA method" content={auditLog.details["mfa_method"]} /> : null}
            {auditLog.details?.hasOwnProperty("context") ?
              <EventDetail title="Context" content={auditLog.details["context"]} /> : null}
            {auditLog.details?.hasOwnProperty("api") ?
              <EventDetail title="API" content={auditLog.details["api"]} /> : null}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

const EventDetail = (props: { title: string, content: string, isLink?: boolean }) => {
  return <Box sx={{ mt: 0.5, mb: 0.5 }}>
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{props.title}</Typography>
    <Typography variant="body2">
      {props.isLink !== undefined && props.isLink ?
        <Link href={`./users/${props.content}`}>{props.content}</Link> : props.content}
    </Typography>
  </Box>
}

import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Organization } from "../../../models/Organization";
import { Await, createSearchParams, useLoaderData, useOutletContext } from "react-router-dom";
import { ApiClient } from "../../../client/ApiClient";
import { OpenInNew } from "@mui/icons-material";
import { Subscription } from "../../../models/Subscription";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { LoadingButton } from "@mui/lab";
import { Free } from "./Free";
import { Paid } from "./Paid";
import { BillingUsage } from "../../../models/BillingUsage";
import { ReactComponent as CardSettingsIcon } from '../../../assets/card_settings.svg'
import { PlanChip } from "../../../components/PlanChip";
import { ErrorPage } from "../../ErrorPage";

const Billing = () => {
  const data = useLoaderData() as any
  const organization = useOutletContext() as Organization
  const { error } = useContext(SnackbarContext)
  const [isPortalLoading, setIsPortalLoading] = useState(false)

  const getPortalSession = async () => {
    setIsPortalLoading(true)
    try {
      const location = encodeURIComponent(document.location.toString())
      const queryParams = createSearchParams({ 'return_url': location })
      const customerPortalSession = await ApiClient.getStripeCustomerPortalSession(organization.id, queryParams)
      window.location.href = customerPortalSession.url
    } catch {
      error("failed to get portal session")
      setIsPortalLoading(false)
    }
  }

  useEffect(() => {
    setIsPortalLoading(false)
  }, [])

  return (
    <Box maxWidth="1062px">
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}>
      </Box>
      <React.Suspense
        fallback={<CircularProgress color="success" />}
      >
        <Await resolve={Promise.all<Subscription | null | BillingUsage>([data.subscription, data.usage])}
               errorElement={<ErrorPage title="Failed to load billing page" description="Try to reload the page." />}>
          {(d: Awaited<Subscription | null | BillingUsage>[]) => (
            <>
              <Box sx={{ display: 'flex', gap: 1, mt: 1, mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Your plan</Typography>
                <PlanChip
                  plan={d[0] !== null && (d[0] as Subscription).is_active ? (d[0] as Subscription).type === "standard" ? "Pro" : "Enterprise" : "Starter"}
                  type="" organization={organization} />
              </Box>
              <Box sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                px: 3,
                py: 2,
                border: '1px solid #262B35',
                borderRadius: 4,
                boxShadow: '0px 4px 4px 0px #000000',
                mb: 5,
                minHeight: '74px'
              }}>
                <Box sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: { xs: 'unset', md: 'center' },
                  flexDirection: { xs: 'column', md: 'row' },
                }}>
                  <CardSettingsIcon />
                  <Typography variant="body1">Manage your subscription and invoices</Typography>
                </Box>
                <LoadingButton variant="contained" loading={isPortalLoading} onClick={getPortalSession}
                               endIcon={<OpenInNew />}>Billing portal</LoadingButton>
              </Box>
              {
                d[0] !== null && (d[0] as Subscription).is_active ?
                  <Paid organization={organization} subscription={d[0] as Subscription}
                        usage={d[1] as BillingUsage} /> :
                  <Free organization={organization} usage={d[1] as BillingUsage} />
              }
            </>
          )}
        </Await>
      </React.Suspense>
    </Box>
  )
}

export default Billing

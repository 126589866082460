import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import EmailOutlined from "@mui/icons-material/EmailOutlined";

interface Props {
  sx?: SxProps<Theme>
}

export const ContactSalesCard = (props: Props) => {
  const { sx } = props
  return <Box sx={{
    borderRadius: '16px',
    px: 4,
    py: 3,
    border: '1px solid #262B35',
    gap: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: { xs: 'unset', sm: '336px' },
    boxShadow: '0px 4px 4px 0px #000000',
    ...sx
  }}>
    <Typography variant="body2">You have more advanced requirements? We're happy to help!</Typography>
    <Button variant="text" startIcon={<EmailOutlined />} sx={{ width: 'fit-content' }} href="https://www.hanko.io/sales"
            target="_blank">Contact sales</Button>
  </Box>
}
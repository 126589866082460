import { createSearchParams } from "react-router-dom";
import { BillingUsage } from "../../../models/BillingUsage";
import { useContext, useEffect, useState } from "react";
import { Organization } from "../../../models/Organization";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Box, Button, styled, Typography } from "@mui/material";
import { UsageCard } from "../../../components/UsageCard";
import { LoadingButton } from "@mui/lab";
import { ContactSalesCard } from "./ContactSalesCard";

const CustomUl = styled("ul")({
  margin: 0
})

interface Props {
  organization: Organization
  usage: BillingUsage
}

export const Free = (props: Props) => {
  const { organization, usage } = props
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
  const { error } = useContext(SnackbarContext)

  const getCheckoutSession = async () => {
    setIsCheckoutLoading(true)
    try {
      const location = encodeURIComponent(document.location.toString())
      const queryParams = createSearchParams({ 'success_url': location, 'cancel_url': location })
      const checkoutSession = await ApiClient.getStripeCheckoutSession(organization.id, queryParams)
      window.location.href = checkoutSession.url
    } catch {
      error("failed to upgrade")
      setIsCheckoutLoading(false)
    }
  }

  useEffect(() => {
    setIsCheckoutLoading(false)
  }, [])

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
    <Box sx={{ display: 'flex', width: '100%', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
      <UsageCard title="MAU" max={organization.limit.max_monthly_active_users} current={usage.mau.total} />
      <UsageCard title="Projects" max={organization.limit.max_projects} current={usage.projects} />
    </Box>
    <Box sx={{
      borderRadius: '16px',
      px: 4,
      py: 3,
      border: '1px solid #262B35',
      gap: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 4px 4px 0px #000000'
    }}>
      <Typography variant="body2" fontWeight={700}>Not included in Starter plan:</Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: { xs: 'column', lg: 'row' } }}>
        <CustomUl>
          <li><Typography variant="body2">Team management</Typography></li>
          <li><Typography variant="body2">Webhooks</Typography></li>
          <li><Typography variant="body2">Admin API Access</Typography></li>
        </CustomUl>
        <CustomUl>
          <li><Typography variant="body2">Custom SMTP server</Typography></li>
          <li><Typography variant="body2">SAML Enterprise SSO</Typography></li>
          <li><Typography variant="body2">Developer support by Hanko team</Typography></li>
        </CustomUl>
        <Box sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          flexDirection: { xs: 'column', md: 'row' }
        }}>
          <Button href="https://hanko.io/pricing" target="_blank" sx={{ width: { xs: '100%', md: '160px' } }}>Compare
            plans</Button>
          <LoadingButton variant="contained" loading={isCheckoutLoading} onClick={getCheckoutSession}
                         sx={{ width: { xs: '100%', md: '160px' } }}>Upgrade to Pro</LoadingButton>
        </Box>
      </Box>
    </Box>
    <ContactSalesCard />
  </Box>
}

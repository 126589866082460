import { useOutletContext, useRevalidator } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Box, Button, CircularProgress, Link, Stack, TextField, Typography, useTheme } from "@mui/material";
import { Width } from "../../../const/Width";
import { SettingsHead } from "../../../components/form/SettingsHead";
import { FormButtons } from "../../../components/form/FormButtons";
import { CustomDomain as CustomDomainDto, Project } from "../../../models/Project";
import { Organization } from "../../../models/Organization";
import { SettingsSwitchOptionWrapper } from "../../../components/form/OptionsWrapper";
import { ApiClient } from "../../../client/ApiClient";
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockOutlineIcon from "@mui/icons-material/BlockOutlined";
import { LoadingButton } from "@mui/lab";

export const CustomDomain = () => {
  const { project, organization } = useOutletContext() as ContextType
  const [loading, setIsLoading] = useState(false)
  const revalidator = useRevalidator()
  const { error, success } = useContext(SnackbarContext)
  const theme = useTheme()

  const restartVerification = async () => {
    setIsLoading(true)
    try {
      await ApiClient.reactivateCustomDomainVerification(organization.id, project.id)
      revalidator.revalidate()
      success("Verification reactivated")
    } catch (e) {
      error("Failed to reactivate verification")
    }
    setIsLoading(false)
  }

  return <Box width={Width.NORMAL}>
    <SettingsSwitchOptionWrapper sx={{
      borderBottom: `1px solid ${theme.palette.grey["900"]}`
    }}>
      <SettingsHead description="Configure a custom domain for the API of this Hanko project." title="Custom domain"
                    sx={{ mb: 1 }} />
      {project.custom_domain ? <CustomDomainStatus organization={organization} project={project} /> : null}
    </SettingsSwitchOptionWrapper>
    {project.custom_domain?.is_suspended === true ? <Alert severity="warning" variant="outlined" sx={{ mb: 4 }}>
      The required DNS entry could not be verified within 4 hours. Please check your DNS settings and restart the
      verification process. If you still have problems, please contact <Link href="mailto:support@hanko.io"
                                                                             target="_blank">support@hanko.io</Link>.
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <LoadingButton variant="text" loading={loading} onClick={restartVerification}>Restart
          verification</LoadingButton>
      </Box>
    </Alert> : null}
    {project.custom_domain !== undefined ? <CustomDomainDetail project={project} organization={organization} /> :
      <CreateCustomDomain project={project} organization={organization} />}
  </Box>
}

const CreateCustomDomain = (props: { organization: Organization, project: Project }) => {
  const { organization, project } = props
  const [state, setState] = useState<{ custom_domain?: string }>({})
  const [loading, setIsLoading] = useState(false)
  const { error, success } = useContext(SnackbarContext)
  const revalidator = useRevalidator()

  const onCancel = () => {
    setState({
      ...state,
      custom_domain: undefined
    })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await ApiClient.createCustomDomain(organization.id, project.id, {
        domain: state.custom_domain ?? "",
      })
      revalidator.revalidate()
      success("Custom domain is set up")
    } catch (e) {
      error("Failed to set up custom domain")
    }
    setIsLoading(false)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  return <Box sx={{
    width: '100%',
  }}>
    <Alert severity="warning" variant="outlined">Setting up a custom domain changes your Hanko API URL. <br />
      You also need to change the redirect URLs at your configured social and enterprise SSO providers.</Alert>
    <Typography variant="body2" sx={{ mt: 3, mb: 2 }}>Enter custom domain:</Typography>
    <form onSubmit={onSubmit} style={{
      width: '100%'
    }}>
      <TextField type="text" required name="custom_domain" placeholder="E.g. auth.example.com" label="Custom domain"
                 fullWidth onChange={onChange} value={state.custom_domain ?? ""} inputProps={{
        pattern: "([a-zA-Z0-9]{1}[a-zA-Z0-9\\-]{0,62})(\\.[a-zA-Z0-9]{1}[a-zA-Z0-9\\-]{0,62})*?(\\.[a-zA-Z]{1}[a-zA-Z0-9]{0,62})\\.?",
        title: "Please enter a fully qualified domain name (FQDN)"
      }} />
      <FormButtons onCancel={onCancel} loading={loading} disabled={(state.custom_domain ?? "") === ""}
                   submitButtonText="Set up" sx={{ mt: 3 }} />
    </form>
  </Box>
}

interface CustomDomainDetailProps {
  organization: Organization
  project: Project
}

const CustomDomainDetail = (props: CustomDomainDetailProps) => {
  const { organization, project } = props
  const [open, setOpen] = useState(false)
  const revalidator = useRevalidator()
  const { error, success } = useContext(SnackbarContext)

  const onDelete = async () => {
    try {
      await ApiClient.deleteCustomDomain(organization.id, project.id)
      revalidator.revalidate()
      setOpen(false)
      success("Custom domain deleted")
    } catch (e) {
      error("Failed to delete custom domain")
    }
  }

  const dnsName = project.custom_domain?.domain?.split(".").slice(0, -2).join(".") ?? ""

  return <Box sx={{
    width: '100%'
  }}>
    <TextField type="text" name="custom_domain"
               label="Custom domain"
               aria-readonly={true}
               InputProps={{ readOnly: true }}
               InputLabelProps={{ shrink: true }}
               defaultValue={project.custom_domain?.domain}
               fullWidth />

    <Typography variant="body2" sx={{ mt: 5 }}>Create the following DNS record at your domain provider:</Typography>
    <Box sx={{
      display: 'flex',
      flexDirection: { sm: 'column', md: 'row' },
      gap: 8,
      mt: 2,
      width: '100%',
      padding: 2,
      borderRadius: '8px',
      border: '0.6px solid #4D566A'
    }}>
      <DnsValue name="Type" value="CNAME" />
      <DnsValue name="Name" value={dnsName} />
      <DnsValue name="Value" value={project.custom_domain?.expected_cname ?? ""} />
    </Box>
    <Button color="error" sx={{ mt: 5 }} onClick={() => setOpen(true)}>Delete custom domain</Button>
    <ConfirmationModal open={open} onClose={() => setOpen(false)} onConfirm={onDelete} title="Delete custom domain"
                       description="Are you sure you want to delete the custom domain?" buttonText="Delete" />
  </Box>
}

const DnsValue = (props: { name: string, value: string }) => {
  const { name, value } = props

  return <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Typography variant="caption" sx={{ fontWeight: 700 }}>{name}</Typography>
    <Typography variant="caption" fontFamily="IBM Plex mono, monospace">{value}</Typography>
  </Box>
}

const CustomDomainStatus = (props: { organization: Organization, project: Project }) => {
  const { organization, project } = props
  const [status, setStatus] = useState<CustomDomainDto>(project.custom_domain!!)
  const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const theme = useTheme()
  const revalidator = useRevalidator()

  const getCustomDomainStatus = async () => {
    try {
      const status = await ApiClient.getCustomDomainStatus(organization.id, project.id)
      setStatus(status)
      if (status.is_active || status.is_suspended) {
        clearIntervalIfDefined()
        revalidator.revalidate()
      }
    } catch (e) {
      console.log("failed to get custom domain status: ", e)
    }
  }

  const clearIntervalIfDefined = () => {
    if (interval !== undefined && interval.current !== undefined) {
      clearInterval(interval.current)
      interval.current = undefined
    }
  }

  useEffect(() => {
    setStatus(project.custom_domain!!)
    if (project.custom_domain?.is_active === false && project.custom_domain?.is_suspended === false) {
      interval.current = setInterval(getCustomDomainStatus, 10000)
    }
    return () => {
      clearIntervalIfDefined()
    }
  }, [project.custom_domain])


  return <>
    {status.is_active ?
      <Box bgcolor="#AAF0CA21"
           borderRadius="16px"
           color={theme.palette.success.main} paddingLeft="8px"
           paddingRight="16px"
           paddingY="6.5px">
        <Stack direction="row" alignItems="center" gap={1} justifyContent="space-between"
               color={theme.palette.success.main}>
          <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
          Active
        </Stack>
      </Box> : status.is_suspended ? <Box bgcolor="#BAE3FE29"
                                          borderRadius="16px"
                                          color={theme.palette.primary.main}
                                          paddingLeft="8px"
                                          paddingRight="16px"
                                          paddingY="6.5px">
        <Stack direction="row" alignItems="center" gap={1}>
          <BlockOutlineIcon sx={{ color: theme.palette.primary.main }} />
          Verification suspended
        </Stack>
      </Box> : <Box bgcolor="#BAE3FE29"
                    borderRadius="16px"
                    color={theme.palette.primary.main}
                    paddingLeft="8px"
                    paddingRight="16px"
                    paddingY="6.5px">
        <Stack direction="row" alignItems="center" gap={1}>
          <CircularProgress color="primary" size={20} />
          Pending
        </Stack>
      </Box>}
  </>

}
